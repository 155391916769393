import { configureStore } from "@reduxjs/toolkit";

import providers from "./providers/slice";
import jwks from "./jwks/slice";
import clients from "./clients/slice";
import requests from "./requests/slice";

const reformat = (oldStore: any) => {
  console.log('reformat', oldStore);
  const providers = { ids : [], entities: {} };
  const jwks = { ids : [], entities: {} };
  const clients = { ids : [], entities: {} };
  const requests = { ids : [], entities: {} };
  Object.keys(oldStore.providers.list).forEach(key => {
    const provider = oldStore.providers.list[key];
    console.log({provider})
    providers.ids.push(key);
    providers.entities[key] = {id: provider.metadata.issuer, name: provider.name, metadata: provider.metadata};

    jwks.ids.push(provider.metadata.issuer);
    jwks.entities[key] = {id : provider.metadata.issuer, keys: provider.metadata.jwks};

    Object.keys(provider.clients.list || {}).forEach(client => {
      clients.ids.push(client);
      clients.entities[client] = { id: client, ...provider.clients.list[client], issuer: provider.metadata.issuer };
    });

    Object.keys(provider.requests.list || {}).forEach(request => {
      requests.ids.push(request);
      requests.entities[request] = { id: request, ...provider.requests.list[request], issuer: provider.metadata.issuer };
    });
  });

  return {providers, jwks, clients, requests};

  // return oldStore;
}

const read = () => {
  let resp;
  try {
    resp = JSON.parse(localStorage.getItem("workspace"));
  } catch (ignored) {}
  if (resp?.providers?.list && !resp?.providers?.entities) {
    return reformat(resp);
  }
  return resp || { providers: { ids: [], entities: {} } };
};

export const store = configureStore({
  reducer: {
    providers,
    jwks,
    clients,
    requests,
  },
  preloadedState: read(),
});

store.subscribe(() =>
  localStorage.setItem("workspace", JSON.stringify(store.getState()))
);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
