import React, { useState } from "react";
import { Provider } from "react-redux";
import {
  Container,
  Tab,
  Modal,
  Button,
  Card,
  Form,
  Grid,
} from "semantic-ui-react";

import { store } from "./store";

import * as Providers from "./providers";
import { PlaygroundSection } from "components/tools2";

const Ui = () => {
  const [open, setOpen] = useState(false);

  const panes = [
    {
      menuItem: "Authentication",
      active: true,
      render: () => (
        <Tab.Pane>
          <Grid stackable padded="horizontally">
            <Grid.Row>
              <Grid.Column width={4}>
                <Card fluid header="Choose a Provider"></Card>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Token Management",
      active: false,
      render: () => (
        <Tab.Pane>
          <Grid stackable padded="horizontally">
            <Grid.Row>
              <Grid.Column width={4}>
                <Card fluid header="Choose a Provider"></Card>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Tab.Pane>
      ),
    },
  ];


  return (
    <Provider store={store}>
      <Container fluid style={{padding: '2em'}}>
        <Card fluid>
          <Card.Content>
            <Card.Header>
              <Form>
                <Form.Group widths="equal">
                  <Providers.Select />
                </Form.Group>
                <Modal
                  onClose={() => setOpen(false)}
                  onOpen={() => setOpen(true)}
                  open={open}
                  centered={false}
                  trigger={
                    <Button
                      floated="right"
                      size="mini"
                      icon="setting"
                      content="Manage"
                    />
                  }
                  size="large"
                >
                  <Modal.Content>
                    <Modal.Description>
                      <Providers.List />
                    </Modal.Description>
                  </Modal.Content>
                </Modal>
              </Form>
            </Card.Header>
          </Card.Content>
        </Card>
      </Container>
      <Container fluid style={{ padding: "0 1em" }}>
        <Tab panes={panes} grid={{ style: { paddingLeft: 0, paddingRight: 0 } }} />
      </Container>
    </Provider>
  );
};

export default Ui;
