import React, { useState } from "react";
import {
  Input,
  Button,
  Dropdown,
  Form,
  Menu,
  Grid,
  Modal,
  Card,
  Icon,
} from "semantic-ui-react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { useAppSelector, useAppDispatch } from "../hooks";
import { IClient, add, update, remove } from "./slice";
import { IProvider } from "../providers/slice";
import * as AuthorizationServer from "../../../tools/oauth2/provider";
import { uuidv4 } from "../../../tools/oauth2/utils";

const empty = function empty(issuer): IClient {
  const id = uuidv4();
  return {
    id,
    client_id: "",
    client_name: "",
    client_secret: "",
    scopes: [],
    token_endpoint_auth_method:
      AuthorizationServer.ClientAuthenticationMethod.client_secret_post,
    issuer,
  };
};

export const List = ({ provider }) => {
  const list = useAppSelector(state =>
    state.clients.ids
      .filter(id => state.clients.entities[id].issuer === provider.issuer)
      .map(id => state.clients.entities[id])
  );
  const dispatch = useAppDispatch();
  return (
    <Card fluid>
      <Card.Content>
        <Card.Header>
          <span>Clients</span>
          <Button floated="right" size="mini" icon="add" onClick={()=> dispatch(add(empty(provider.issuer)))}/>
        </Card.Header>
      </Card.Content>
      <Card.Content>
        {list.length === 0 ? (
          "Nothing to show"
        ) : (
          <Menu vertical fluid>
            {list.map(client => (
              <Menu.Item key={client.id} as="a">
                <Grid columns={2}>
                  <ItemModal provider={provider} client={client} defaultOpen={client.client_name === ""}>
                    <Grid.Row>
                      <Grid.Column verticalAlign="middle">
                        <span>{client.client_name}</span>
                      </Grid.Column>
                      <Grid.Column>
                        <Button.Group size="small" floated="right">
                          <Button
                            negative
                            icon="trash alternate"
                            onClick={e => {
                              e.stopPropagation();
                              dispatch(remove(client.id))
                            }}
                          />
                        </Button.Group>
                      </Grid.Column>
                    </Grid.Row>
                  </ItemModal>
                </Grid>
              </Menu.Item>
            ))}
          </Menu>
        )}
      </Card.Content>
    </Card>
  );
};

export const ItemModal = ({
  children,
  provider,
  client,
  defaultOpen
}: {
  client: IClient;
  children: React.ReactNode;
  provider: IProvider;
  defaultOpen?: boolean
}) => {
  const [open, setOpen] = useState(defaultOpen || false);
  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={children}
      centered={false}
    >
      <Modal.Header>Client</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Item provider={provider} client={client} />
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export const Item = ({
  client,
  provider,
}: {
  client: IClient;
  provider: IProvider;
}) => {
  const dispatch = useAppDispatch();

  const redirect_uri =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname;
  return (
    <Form>
      <Form.Group widths="equal">
        <Form.Field>
          <label htmlFor="redirect_uri">redirect_uri</label>
          <Input
            placeholder="redirect_uri"
            id="redirect_uri"
            icon={
              <CopyToClipboard
                text={redirect_uri}
                onCopy={() => console.log("copied")}
              >
                <Icon name="copy" link />
              </CopyToClipboard>
            }
            value={redirect_uri}
            readOnly
          ></Input>
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field>
          <label htmlFor="client_name">client_name</label>
          <Input
            placeholder="client_name"
            id="client_name"
            onChange={e => dispatch(update({id: client.id, changes: {client_name: e.target.value}}))}
            value={client.client_name}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field>
          <label htmlFor="client_id">client_id</label>
          <Input
            placeholder="client_id"
            id="client_id"
            onChange={e => dispatch(update({id: client.id, changes: {client_id: e.target.value}}))}
            value={client.client_id}
          />
        </Form.Field>
        <Form.Field>
          <label htmlFor="client_secret">client_secret</label>
          <Input
            placeholder="client_secret"
            id="client_secret"
            onChange={e => dispatch(update({id: client.id, changes: {client_secret: e.target.value}}))}
            value={client.client_secret}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field>
          <label>scopes</label>
          <Dropdown
            options={Array.from(
              provider.metadata.scopes_supported || []
            ).map(scope => ({ key: scope, value: scope, text: scope }))}
            placeholder="scopes"
            search
            multiple
            selection
            fluid
            allowAdditions
            value={client.scopes || []}
            onAddItem={(_, { value }) => {
              dispatch(update({id: client.id, changes: {scopes: [...client.scopes, value as string]}}))
            }}
            onChange={(_, data) => {
              dispatch(update({id: client.id, changes: {scopes: [...((data.value as string[]) || [])]}}))
            }}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field>
          <label>token_endpoint_auth_method</label>
          <Dropdown
            options={Array.from(
              provider.metadata.token_endpoint_auth_methods_supported || []
            ).map(method => ({
              key: method,
              value: method,
              text: method,
            }))}
            placeholder="token_endpoint_auth_method"
            search
            selection
            fluid
            clearable
            value={client.token_endpoint_auth_method}
            onChange={(_, data) => {
              dispatch(update({id: client.id, changes: {token_endpoint_auth_method: data.value as AuthorizationServer.ClientAuthenticationMethod}}))
            }}
          />
        </Form.Field>
        <Form.Field>
          <label>Code Challenge Method</label>
          <Dropdown
            options={Object.keys(AuthorizationServer.CodeChallengeMethod).map(
              method => {
                return {
                  text: method,
                  value: method,
                  key: method,
                };
              }
            )}
            placeholder="code_challenge_method"
            clearable
            search
            selection
            fluid
            value={client.code_challenge_method}
            onChange={(_, data) => {
              dispatch(update({id: client.id, changes: {code_challenge_method: data.value as AuthorizationServer.CodeChallengeMethod}}))
            }}
          />
        </Form.Field>
      </Form.Group>
    </Form>
  );
};
