import React, { useState } from "react";
import {
  Input,
  Tab,
  Button,
  Dropdown,
  Form,
  Menu,
  Grid,
  Modal,
  Dimmer,
  Loader,
  Card,
} from "semantic-ui-react";

import { useAppSelector, useAppDispatch } from "../hooks";
import * as Provider from "../../../tools/oauth2/provider";

import { activate, update, add, remove, IProvider, fetchMetadata } from "./slice";

import { Item as Jwks } from '../jwks';
import { List as Clients } from '../clients';

export const List = () => {
  const { entities = {} } = useAppSelector(state => state.providers);
  const dispatch = useAppDispatch();

  return (
    <Card fluid>
      <Card.Content>
        <Card.Header>
          <span>Providers</span>
          <ItemModal
            provider={{
              name: "",
              issuer: "https://",
            }}
          >
            <Button icon="add" floated="right" circular color="blue" />
          </ItemModal>
        </Card.Header>
      </Card.Content>
      {Object.keys(entities).length > 0 ? (
        <Card.Content>
          <Menu vertical fluid>
            {Object.entries(entities).map(([key, provider]) => (
              <Menu.Item key={key} as="a">
                <Grid columns={2}>
                  <ItemModal provider={provider}>
                    <Grid.Row>
                      <Grid.Column verticalAlign="middle">
                        <span>{provider.name}</span>
                      </Grid.Column>
                      <Grid.Column>
                        <Button
                          negative
                          floated="right"
                          icon="trash alternate"
                          size="small"
                          onClick={e => {
                            e.stopPropagation();
                            dispatch(remove(provider.issuer));
                          }}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </ItemModal>
                </Grid>
              </Menu.Item>
            ))}
          </Menu>
        </Card.Content>
      ) : (
        ""
      )}
    </Card>
  );
};

export const Select = () => {
  // The `state` arg is correctly typed as `RootState` already
  const { entities, active } = useAppSelector(state => state.providers);
  const dispatch = useAppDispatch();

  if (!entities || Object.keys(entities).length == 0) return <div></div>;

  return (
    <Form.Field>
      <label htmlFor="providers">Providers</label>
      <Dropdown
        id="providers"
        name="providers"
        placeholder="Select Provider"
        fluid
        selection
        value={active}
        options={Object.entries(entities).map(([key, value]) => {
          return {
            key: key,
            text: value.name,
            value: key,
          };
        })}
        onChange={(_, { value }: { value: string }) =>
          dispatch(activate({ active: value }))
        }
      />
    </Form.Field>
  );
};

export const Item = ({
  provider,
  onSave,
}: {
  provider: IProvider;
  onSave: () => void;
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  if (!provider.metadata) {
    const [name, setName] = useState(provider.name);
    const [issuer, setIssuer] = useState(provider.issuer);
    return (
      <Form>
        <Dimmer active={loading}>
          <Loader>Loading</Loader>
        </Dimmer>
        <Form.Group widths="equal">
          <Form.Field>
            <label htmlFor="name">Name</label>
            <Input
              placeholder="Name"
              id="name"
              onChange={e => setName(e.target.value)}
              value={name}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <label htmlFor="issuer">Issuer</label>
            <Input
              placeholder="Issuer"
              id="issuer"
              onChange={e => setIssuer(e.target.value)}
              value={issuer}
            />
          </Form.Field>
        </Form.Group>
        <Button.Group floated="right" style={{ padding: "2rem" }}>
          <Button
            color="blue"
            onClick={async () => {
              setLoading(true);
              try {
                const metadata = await dispatch(fetchMetadata(issuer)).unwrap();
                dispatch(add({ name, issuer, metadata }));
              } catch (e) {
                dispatch(add({ name, issuer, metadata: { issuer } }));
              }
              onSave();
            }}
          >
            Add
          </Button>
          <Button color="red" onClick={() => onSave()}>
            Cancel
          </Button>
        </Button.Group>
      </Form>
    );
  }

  return (
    <Form>
      <Dimmer active={loading}>
        <Loader>Loading</Loader>
      </Dimmer>
      <Form.Group widths="equal">
        <Form.Field>
          <label htmlFor="name">Name</label>
          <Input
            placeholder="Name"
            id="name"
            onChange={e =>
              dispatch(
                update({
                  id: provider.issuer,
                  changes: { name: e.target.value },
                })
              )
            }
            value={provider.name}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field>
          <label htmlFor="issuer">Issuer</label>
          <Input
            placeholder="Issuer"
            id="issuer"
            action
            value={provider.issuer}
          >
            <input readOnly />
            <Button
              onClick={async () => {
                setLoading(true);
                try {
                  const metadata = await dispatch(fetchMetadata(provider.issuer)).unwrap();
                  dispatch(update({ id: provider.issuer, changes: { metadata } }));
                } catch (ignored) {
                }
                setLoading(false);
              }}
            >
              Fetch
            </Button>
          </Input>
        </Form.Field>
      </Form.Group>

      <Tab
        panes={[
          {
            menuItem: "Endpoints",
            render: () => (
              <Tab.Pane>
                <Endpoints provider={provider} />
              </Tab.Pane>
            ),
          },
          {
            menuItem: "Features",
            render: () => <Tab.Pane><Features provider={provider} /></Tab.Pane>,
          },
          {
            menuItem: "JWKS",
            render: () => <Tab.Pane><Jwks provider={provider} /></Tab.Pane>,
          },
          {
            menuItem: "Clients",
            render: () => <Tab.Pane><Clients provider={provider} /></Tab.Pane>,
          },
          {
            menuItem: "APIs",
            render: () => <Tab.Pane></Tab.Pane>,
          },
        ]}
      ></Tab>
    </Form>
  );
};

export const ItemModal = ({
  children,
  provider,
}: {
  provider: IProvider;
  children: React.ReactNode;
}) => {
  const [open, setOpen] = useState(false);
  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={children}
      size="large"
      centered={false}
    >
      <Modal.Header>Provider</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Item provider={provider} onSave={() => setOpen(false)} />
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export const Endpoints = ({ provider }: { provider: IProvider }) => {
  const dispatch = useAppDispatch();
  return (
    <>
      <Form.Group widths="equal">
        <Form.Field>
          <label htmlFor="authorization_endpoint">authorization_endpoint</label>
          <Input
            placeholder="authorization_endpoint"
            id="authorization_endpoint"
            value={provider.metadata.authorization_endpoint}
            onChange={e =>
              dispatch(
                update({
                  id: provider.issuer,
                  changes: {
                    metadata: {
                      ...provider.metadata,
                      authorization_endpoint: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field>
          <label htmlFor="userinfo_endpoint">userinfo_endpoint</label>
          <Input
            placeholder="userinfo_endpoint"
            id="userinfo_endpoint"
            value={provider.metadata.userinfo_endpoint}
            onChange={e =>
              dispatch(
                update({
                  id: provider.issuer,
                  changes: {
                    metadata: {
                      ...provider.metadata,
                      userinfo_endpoint: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field>
          <label htmlFor="token_endpoint">token_endpoint</label>
          <Input
            placeholder="token_endpoint"
            id="token_endpoint"
            value={provider.metadata.token_endpoint}
            onChange={e =>
              dispatch(
                update({
                  id: provider.issuer,
                  changes: {
                    metadata: {
                      ...provider.metadata,
                      token_endpoint: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field>
          <label htmlFor="introspection_endpoint">introspection_endpoint</label>
          <Input
            placeholder="introspection_endpoint"
            id="introspection_endpoint"
            value={provider.metadata.introspection_endpoint}
            onChange={e =>
              dispatch(
                update({
                  id: provider.issuer,
                  changes: {
                    metadata: {
                      ...provider.metadata,
                      introspection_endpoint: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field>
          <label htmlFor="revocation_endpoint">revocation_endpoint</label>
          <Input
            placeholder="revocation_endpoint"
            id="revocation_endpoint"
            value={provider.metadata.revocation_endpoint}
            onChange={e =>
              dispatch(
                update({
                  id: provider.issuer,
                  changes: {
                    metadata: {
                      ...provider.metadata,
                      revocation_endpoint: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field>
          <label htmlFor="end_session_endpoint">end_session_endpoint</label>
          <Input
            placeholder="end_session_endpoint"
            id="end_session_endpoint"
            value={provider.metadata.end_session_endpoint}
            onChange={e =>
              dispatch(
                update({
                  id: provider.issuer,
                  changes: {
                    metadata: {
                      ...provider.metadata,
                      end_session_endpoint: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field>
          <label htmlFor="registration_endpoint">registration_endpoint</label>
          <Input
            placeholder="registration_endpoint"
            id="registration_endpoint"
            value={provider.metadata.registration_endpoint}
            onChange={e =>
              dispatch(
                update({
                  id: provider.issuer,
                  changes: {
                    metadata: {
                      ...provider.metadata,
                      registration_endpoint: e.target.value,
                    },
                  },
                })
              )
            }
          />
        </Form.Field>
      </Form.Group>
    </>
  );
};

export const Features = ({ provider }: { provider: IProvider }) => {
  const dispatch = useAppDispatch();

  const onChange = (arg: Partial<Provider.IModel>) => {
    dispatch(
      update({
        id: provider.issuer,
        changes: {
          metadata: {
            ...provider.metadata,
            ...arg,
          },
        },
      })
    );
  };

  return (
    <>
      <Form.Group widths="equal">
        <Form.Field>
          <label htmlFor="grant_types_supported">grant_types_supported</label>
          <Dropdown
            options={Array.from(
              provider.metadata.grant_types_supported || []
            ).map(i => ({ key: i, value: i, text: i }))}
            placeholder="grant_types_supported"
            search
            multiple
            selection
            fluid
            allowAdditions
            value={provider.metadata.grant_types_supported || []}
            onAddItem={(_, { value }) => {
              onChange({
                grant_types_supported: Array.from(
                  new Set([
                    ...(provider.metadata.grant_types_supported || []),
                    value as Provider.GrantType,
                  ])
                ),
              });
            }}
            onChange={(_, data) => {
              onChange({
                grant_types_supported: [
                  ...((data.value as Provider.GrantType[]) || []),
                ],
              });
            }}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field>
          <label htmlFor="response_types_supported">
            response_types_supported
          </label>
          <Dropdown
            options={Array.from(
              provider.metadata.response_types_supported || []
            ).map(i => ({ key: i, value: i, text: i }))}
            placeholder="response_types_supported"
            search
            multiple
            selection
            fluid
            allowAdditions
            value={provider.metadata.response_types_supported || []}
            onAddItem={(_, { value }) => {
              onChange({
                response_types_supported: Array.from(
                  new Set([
                    ...(provider.metadata.response_types_supported || []),
                    value as Provider.ResponseType,
                  ])
                ),
              });
            }}
            onChange={(_, data) => {
              onChange({
                response_types_supported: [
                  ...((data.value as Provider.ResponseType[]) || []),
                ],
              });
            }}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field>
          <label htmlFor="scopes_supported">scopes_supported</label>
          <Dropdown
            options={Array.from(provider.metadata.scopes_supported || []).map(i => ({
              key: i,
              value: i,
              text: i,
            }))}
            placeholder="scopes_supported"
            search
            multiple
            selection
            fluid
            allowAdditions
            value={provider.metadata.scopes_supported || []}
            onAddItem={(_, { value }) => {
              onChange({
                scopes_supported: Array.from(
                  new Set([
                    value as string,
                    ...(provider.metadata.scopes_supported || []),
                  ])
                ),
              });
            }}
            onChange={(_, data) => {
              onChange({
                scopes_supported: [...((data.value as string[]) || [])],
              });
            }}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field>
          <label htmlFor="acr_values_supported">acr_values_supported</label>
          <Dropdown
            options={Array.from(provider.metadata.acr_values_supported || []).map(
              i => ({
                key: i,
                value: i,
                text: i,
              })
            )}
            placeholder="acr_values_supported"
            search
            multiple
            selection
            fluid
            allowAdditions
            value={provider.metadata.acr_values_supported || []}
            onAddItem={(_, { value }) => {
              onChange({
                acr_values_supported: Array.from(
                  new Set([
                    ...(provider.metadata.acr_values_supported || []),
                    value as string,
                  ])
                ),
              });
            }}
            onChange={(_, data) => {
              onChange({
                acr_values_supported: [...((data.value as string[]) || [])],
              });
            }}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field>
          <label htmlFor="claims_supported">claims_supported</label>
          <Dropdown
            options={Array.from(provider.metadata.claims_supported || []).map(i => ({
              key: i,
              value: i,
              text: i,
            }))}
            placeholder="claims_supported"
            search
            multiple
            selection
            fluid
            allowAdditions
            value={provider.metadata.claims_supported || []}
            onAddItem={(_, { value }) => {
              onChange({
                claims_supported: Array.from(
                  new Set([
                    ...(provider.metadata.claims_supported || []),
                    value as string,
                  ])
                ),
              });
            }}
            onChange={(_, data) => {
              onChange({
                claims_supported: [...((data.value as string[]) || [])],
              });
            }}
          />
        </Form.Field>
      </Form.Group>
    </>
  );
};
