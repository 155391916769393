import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { remove as removeProvider } from '../providers/slice';

const adapter = createEntityAdapter<IRequest>({
  sortComparer: false,
});

export type IRequest = {
  id: string;
  issuer: string;
};


export const slice = createSlice({
  name: 'requests',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState: adapter.getInitialState(),
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    update: adapter.updateOne,
    add: adapter.addOne,
    remove: adapter.removeOne
  },
  extraReducers:  (builder) => {
    builder
      .addCase(removeProvider, (state, { payload }) => {
        adapter.removeMany(state, Object.keys(state.entities).filter(entity => state.entities[entity].issuer === payload))
      });
  }
});

export const { update, add, remove } = slice.actions;
export default slice.reducer;