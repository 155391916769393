import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { remove as removeProvider } from '../providers/slice';


export const fetchJwks = createAsyncThunk<IJwks, {jwks_uri: string, issuer: string}>(
  "jwks/fetch",
  async ({jwks_uri, issuer}) => {
    return (await fetch(jwks_uri)
      .then(resp => resp.json())
      .then(resp => ({ ...resp, issuer }))) as IJwks;
  }
);

export interface IJwks {
  keys: any[];
  issuer: string;
}

const adapter = createEntityAdapter<IJwks>({
  selectId: jwks => jwks.issuer,
  sortComparer: false,
});

export const slice = createSlice({
  name: "jwks",
  initialState: adapter.getInitialState(adapter.getInitialState()),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchJwks.fulfilled, (state, { payload }) => {
        if (state.entities[payload.issuer]) {
          adapter.updateOne(state, { id: payload.issuer, changes: payload});
        } else {
          adapter.addOne(state, payload);
        }
      })
      .addCase(removeProvider, (state, { payload }) => {
        adapter.removeMany(state, Object.keys(state.entities).filter(entity => state.entities[entity].issuer === payload))
      });
  }
});

export default slice.reducer;
