import { createSlice, createEntityAdapter, createAsyncThunk } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import * as Provider from "../../../tools/oauth2/provider";
import { fetchJwks } from '../jwks/slice';

export const fetchMetadata = createAsyncThunk<Provider.IModel, string>(
  'providers/fetchMetadata',
  async (issuer, {dispatch}) => {
    const metadata = (await Provider.fromIssuer(issuer)).metadata;
    dispatch(fetchJwks({jwks_uri: metadata.jwks_uri, issuer}));
    return metadata;
  }
)

export interface IProvider {
  name: string;
  issuer: string;
  metadata?: Provider.IModel;
}

const adapter = createEntityAdapter<IProvider>({
  selectId: (provider) => provider.issuer,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const slice = createSlice({
  name: 'providers',
  initialState: adapter.getInitialState(adapter.getInitialState({ active: null } as {active: string})),
  reducers: {
    add: adapter.addOne,
    update: adapter.updateOne,
    activate:(state, action: PayloadAction<{active: string}>) => {
      state.active = action.payload.active;
    },
    remove: adapter.removeOne
  },
});

export const { add, update, remove, activate } = slice.actions;
export default slice.reducer;