import React from "react";
import { Input, Form } from "semantic-ui-react";

import { useAppSelector } from "../hooks";
import CodeBlock from "../../codeblock";
import { IProvider } from "../providers/slice";

export const Item = ({ provider }: { provider: IProvider }) => {
  const jwks = useAppSelector(
    state => state.jwks.entities[provider.metadata.issuer]
  );
  return (
    <>
      <Form.Group widths="equal">
        <Form.Field>
          <label htmlFor="jwks_uri">jwks_uri</label>
          <Input
            placeholder="jwks_uri"
            id="jwks_uri"
            value={provider.metadata.jwks_uri}
          />
        </Form.Field>
      </Form.Group>
      <CodeBlock
        code={JSON.stringify({ keys: jwks?.keys || [] }, null, 2)}
        language="json"
        scroll={true}
        title="Keys"
      />
    </>
  );
};
